@use 'sass:math';

@mixin noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                            supported by Chrome, Edge, Opera and Firefox */
}

@function calculateRem($size) {
  $rem-size: math.div($size, 16px);
  @return #{$rem-size}rem;
}
@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin textOverflowEllipses($line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin onDesktop {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin for-mid-desktop {
  @media screen and (min-width: 1600px) {
    @content;
  }
}

@mixin onTab {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}
@mixin onMobile {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin fromPxToRem($fontSize) {
  font-size: ($fontSize/16) + rem;
}

// Reusable mixing for ring animation

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  animation: #{$str};
  -moz-animation: #{$str};
}

// zoom out animation
@keyframes zoom-out {
  0% {
    transform: scale(0.4, 0.4);
  }
  50% {
    transform: scale(0.6, 0.6);
  }
  100% {
    transform: scale(1.5, 1.5);
  }
}
