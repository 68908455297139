.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0px 5px 20px 0px #00000026;
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  .title {
    font-size: 18px;
    font-weight: 600;
    padding: 0.5rem 0;
    padding-right: 1rem;
  }
  .fbtn {
    padding-left: 8px;
    img {
      color: green;
    }
  }
}
