@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&display=swap');
@import './variables';
@import './mixins';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--figtee);
  scroll-behavior: smooth;
}
.mainFluidApp {
  width: 100%;
  height: 100%;
  position: relative;

  /* Add your background pattern here */

  background-size: 65px 65px;

  @include onMobile {
    height: 100%;
    background-color: inherit;
  }
  @include onDesktop {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-image: repeating-radial-gradient(
      #0c0a0a 80%,
      #2f312f 90%,
      #3f4549 90%
    );
  }
  .fluid {
    // margin: auto;
    position: relative;
    background-color: white;
    min-width: var(--common-width);
    max-width: var(--common-width);
    min-height: var(--common-height);

    @include onMobile {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

p {
  margin: 0;
  padding: 0;
}
button {
  border: none;
  background-color: none;
  background: none;
  padding: 0;
  margin: 0;
}

.color-primary {
  color: var(--primary-green);
}

.custom-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
