.fluidBtn {
  //   background-color: var(--dark-green);
  width: 100%;
  padding: 0.5rem;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}
.loading {
  display: flex;
  justify-content: center;
}
.fluidBtn:hover {
  // opacity: 0.95;
}

.primaryBtn {
  width: 100%;
  .contained {
    color: white;
    background-color: var(--dark-green);
  }
  .outlined {
    padding: 0.38rem;
    border: 1.5px solid var(--dark-green);
    color: var(--dark-green);
    font-weight: 500;
  }
}
.errorBtn {
  width: 100%;
  .contained {
    color: white;
    background-color: var(--error-red);
  }
  .outlined {
    padding: 0.38rem;
    border: 1.5px solid var(--error-red);
    color: var(--error-red);
    font-weight: 500;
  }
}
