@import '../../styles/mixins';

.menuDrawerBox {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.229); /* Adjust the opacity as needed */
  backdrop-filter: blur(8px); /* Adjust the blur radius as needed */
  @include onMobile {
    height: 100vh;
    width: 100vw;
  }
  .boxInside {
    height: 100%;
    width: 100%;
    position: relative;
  }
}
