:root {
  --figtee: 'Figtree', sans-serif;
  --primary-green: #38b869;
  --dark-green: #00270f;
  --light-green: #ecfeef;
  --error-red: #ff6663;
  --carret-purple: #652ac6;
  --pending-yellow: #ffc107;
  --common-height: 40rem;
  --common-width: 23rem;
}
