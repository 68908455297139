.fluidInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  label {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    padding-bottom: 4px;
    padding-left: 4px;
  }
  input {
    // border: 1px solid #e6e6e6;
    background-color: white;
    border-radius: 10px;
    font-size: 1rem;
    padding: 0.5rem 0.3rem;
    &:focus {
      outline: none;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
