.otpContainer {
  :first-child {
    justify-content: space-between;
  }
  input {
    border-radius: 10px;
    border: 1px solid #e6e8ec;
    font-size: 1.3rem;
    padding: 0.5rem 0.3rem;
    width: 14% !important;
  }
  .otpTimerText {
    font-size: 14px;
    font-weight: 400;
    padding-left: 6px;
    padding-top: 5px;
    cursor: default;
  }
  .resendBtn {
    padding-left: 6px;
    padding-top: 5px;
    cursor: pointer;
  }
}
